import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","width":"400"},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dense":"","flat":"","dark":"","tile":""}},[_c(VToolbarTitle,[_vm._v("Tiempo agotado")])],1),_c(VCardText,[_c('div',{staticClass:"my-3"},[_c('span',[_vm._v("Se agotó el tiempo de esta sección. Al dar click en \"Siguiente sección\" podrás continuar con la aplicación del examen.")])]),_c(VBtn,{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.$emit('siguienteSeccion')}}},[_c('span',[_vm._v("Siguiente sección")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }