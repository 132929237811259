<template>
  <v-dialog v-model="mostrar" persistent width="400">
    <v-card>
      <v-toolbar color="primary" dense flat dark tile>
        <v-toolbar-title>Tiempo agotado</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <div class="my-3">
          <span
            >Se agotó el tiempo de esta sección. Al dar click en "Siguiente
            sección" podrás continuar con la aplicación del examen.</span
          >
        </div>
        <v-btn color="primary" small @click="$emit('siguienteSeccion')">
          <span>Siguiente sección</span>
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    mostrar: { type: Boolean, default: false },
  },
};
</script>
